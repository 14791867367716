import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import BusinessCreditServicesIllustration from "../../../svg/ComparisonResultPageIllustrations/businesscreditservice.svg";
import DunBradstreetSVG from "../../../svg/ComparisonResultGridLogos/businesscreditservices/dun_bradstreet25px.svg";
import ExperianSVG from "../../../svg/ComparisonResultGridLogos/businesscreditservices/experian.svg";
import EquifaxSVG from "../../../svg/ComparisonResultGridLogos/businesscreditservices/equifax.svg";
import CreditSafeSVG from "../../../svg/ComparisonResultGridLogos/businesscreditservices/creditsafe.svg";
import TransunionSVG from "../../../svg/ComparisonResultGridLogos/businesscreditservices/transunion.svg";
export const pageMetaData = {
  pageTitle: "Compare Business Credit Monitoring Services",
  pageDescription: "Monitor Your Credit From $39.95",
  pageImagePath: "/businesscreditservices.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Plan Price"
      }, {
        index: 2,
        text: "Free Credit Report"
      }, {
        index: 3,
        text: "Credit Score Scale"
      }, {
        index: 4,
        text: "Ability To Build Credit"
      }, {
        index: 5,
        text: "Security Freeze"
      }, {
        index: 6,
        text: "Fraud Alerts"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Dun & Bradstreet",
          subText: "",
          imageKey: "dunbradstreet"
        }
      }, {
        index: 2,
        text: "149",
        isPriceCell: true,
        showRating: true,
        showSavingText: true,
        savingText: "From Per Month",
        disableSavingStrikeThrough: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "PAYDEX 0 - 100"
      }, {
        index: 5,
        useIcon: true,
        icon: "tick"
      }, {
        index: 6,
        useIcon: true,
        icon: "tick"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://dandb.7eer.net/c/1323808/87234/2075",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Dun & Bradstreet",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Experian",
          subText: "",
          imageKey: "experian"
        }
      }, {
        index: 2,
        text: "189",
        isPriceCell: true,
        showSavingText: true,
        savingText: "From Per Year",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 2
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Intelliscore Plus 1- 100"
      }, {
        index: 5,
        useIcon: true,
        icon: "tick"
      }, {
        index: 6,
        useIcon: true,
        icon: "tick"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "http://www.experian.com/small-business/establish-business-credit.jsp",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Experian",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Equifax Small Business",
          subText: "",
          imageKey: "equifax"
        }
      }, {
        index: 2,
        text: "19.95",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        disableDollarPrefix: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Business Credit Risk 101 - 992"
      }, {
        index: 5,
        useIcon: true,
        icon: "tick"
      }, {
        index: 6,
        useIcon: true,
        icon: "tick"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.equifax.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Equifax",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Creditsafe",
          subText: "",
          imageKey: "creditsafe"
        }
      }, {
        index: 2,
        text: "Free",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Credit Report",
        disableSavingStrikeThrough: true,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Creditsafe Credit Score 0 - 100"
      }, {
        index: 5,
        useIcon: true,
        icon: "cross"
      }, {
        index: 6,
        useIcon: true,
        icon: "cross"
      }, {
        index: 6,
        useIcon: true,
        icon: "cross"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.creditsafe.com/us/en.html",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Creditsafe",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Transunion",
          subText: "",
          imageKey: "transunion"
        }
      }, {
        index: 2,
        text: "24.95",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 5
      }, {
        index: 3,
        useIcon: true,
        icon: "cross"
      }, {
        index: 4,
        text: "Unknown"
      }, {
        index: 5,
        useIcon: true,
        icon: "cross"
      }, {
        index: 6,
        useIcon: true,
        icon: "tick"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.transunion.com/business",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up for Transunion",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare business credit monitoring services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<BusinessCreditServicesIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="BusinessCreditServicesIllustration" />, <BusinessCreditServicesIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="BusinessCreditServicesIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <DunBradstreetSVG className="w-full" imagekey="dunbradstreet" mdxType="DunBradstreetSVG" />
  <ExperianSVG className="w-full" imagekey="experian" mdxType="ExperianSVG" />
  <EquifaxSVG className="w-full" imagekey="equifax" mdxType="EquifaxSVG" />
  <CreditSafeSVG className="w-full" imagekey="creditsafe" mdxType="CreditSafeSVG" />
  <TransunionSVG className="w-full" imagekey="transunion" mdxType="TransunionSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="business" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="Business Credit Services" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      